import axios from "axios";
import config from '@/config'

import store from '../store/index.js'

import Cookies from "js-cookie";

const http = axios.create({
    baseURL: config.apiUrl,
});

http.defaults.headers.common['Content-Type'] = 'application/json';

http.defaults.headers.common["Authorization"] =
    "Bearer " + Cookies.get("token");

http.interceptors.response.use(
    response => response,
    error => {
        // if (error.response.status === 401 && error.config.url == "/user-detail/") {
        if (error.response.status === 401) {
            console.log("autenticação expirada");
            store.commit('LOGOUT')
            return
        }

        if (error.response.status >= 500) {
            store.commit('errorStore/SHOW_ERROR', error.response)
            return
        }

        return Promise.reject(error)
    });
export default http