import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
import modules from './modules'
import http from "@/plugins/axios";
import config from '@/config.js';

import { resetRouter } from "@/router/index";

import Cookies from "js-cookie";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawerItens: [],
    permissions: {},
    user: {},
  },
  mutations: {
    LOGOUT(state) {
      Cookies.remove("token", { path: "" });

      router.push("/login");

      resetRouter();

      this.commit("parlamentarDialogStore/CLEAN_PARLAMENTAR")


      state.drawerItens = [];
      state.permissions = {};
      state.user = {}
    },
    LOGIN(state, payload) {
      Cookies.set("token", payload.accessToken, { path: "", expires: 1 });

      http.defaults.headers.common["Authorization"] =
        "Bearer " + payload.accessToken;

      this.commit("makeSessionData", payload.user);

      router.push("/");
    },
    makeSessionData(state, payload) {
      let parlamentar = Cookies.get('parlamentar');
      if (parlamentar) {
        this.commit("parlamentarDialogStore/SET_PARLAMENTAR", JSON.parse(parlamentar))
      }

      let modules = payload.modules
      let user = payload
      delete user.modules

      state.user = user;

      let itens = [{
        to: "/",
        icon: "mdi-view-dashboard",
        text: "Dashboard"
      }]

      if (modules) {
        modules.forEach((module) => {
          var item = {
            id: module._id,
            text: module.name,
            icon: module.icon,
            subLinks: [],
          };

          module.services.forEach((service) => {
            let temp = state.permissions[service.route.toLowerCase()]

            if (temp == undefined) {
              temp = service.permissions
            } else {
              temp.push(...service.permissions)
            }

            state.permissions[service.route.toLowerCase()] = temp

            let routeName = service.route.toLowerCase();
            router.addRoute({
              path: `/${routeName}`,
              name: service.name,
              meta: { icon: service.icon, requiresAuth: true },
              component: () => import(`@/views/${routeName}/${routeName}.vue`)
            });

            item.subLinks.push({
              text: service.name,
              to: `/${routeName}`,
              icon: service.icon,
            });
          });
          var isNewModule = true;
          itens.forEach(element => {
            if (item.id == element.id) {
              isNewModule = false
            }
          });
          if (isNewModule)
            itens.push(item);
        });

        state.drawerItens = itens
      }
    },
    UPDATE_USER(state, payload) {
      state.user.name = payload.name
      state.user.email = payload.email
    },
  },
  actions: {
    async getData(store) {
      document.title = config.projectName;

      const TOKEN = Cookies.get("token");

      // await new Promise(r => setTimeout(r, 10000));

      if (TOKEN) {
        try {
          let data = await http.get("/user-detail/");
          store.commit("makeSessionData", data.data)
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
  getters: {
    isMaster(state) {
      return state.user.master
    }
  },
  modules: modules
})
