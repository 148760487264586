import Cookies from "js-cookie";
import http from "@/plugins/axios";
import router from '@/router'




export default {
    namespaced: true,
    state: {
        parlamentarDialog: false,
        selectedParlamentar: {}
    },
    mutations: {
        SHOW_PARLAMENTAR_DIALOG(state, payload) {
            state.parlamentarDialog = true
        },
        CLOSE_PARLAMENTAR_DIALOG(state) {
            state.parlamentarDialog = false
        },
        SET_PARLAMENTAR(state, payload) {
            let temp = JSON.stringify(payload)
            state.selectedParlamentar = payload
            Cookies.set("parlamentar", temp, { path: "", });

            http.defaults.headers.common["X-Parlamentar"] = payload._id;
        },
        CLEAN_PARLAMENTAR(state, payload) {
            Cookies.remove("parlamentar", { path: "" });
            state.selectedParlamentar = {}

            delete http.defaults.headers.common["X-Parlamentar"];
        }
    },
    actions: {
    },
    modules: {
    }
}