export default {
    namespaced: true,
    state: {
        passwordDialog: false,
    },
    mutations: {
        SHOW_PASSWORD_DIALOG(state) {
            state.passwordDialog = true
        },
        CLOSE_PASSWORD_DIALOG(state) {
            state.passwordDialog = false
        },
    },
}