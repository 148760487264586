import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import config from './config'
import AlgCrud from "alglibs";
import * as VueGoogleMaps from "vue2-google-maps" // Import package


Vue.config.productionTip = false

Vue.use(AlgCrud, {
  router: router,
  store: store,
  color: config.primaryColor,
  apiUrl: config.apiUrl,
})


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC8SHNy6QRSH9uTZloISkHuWqS5K4Yi5EQ",
    region: 'BR',
    language: 'pt',
  }
});


// executa o get userDetails antes do build do vue, montando as rotas corretamente
store.dispatch('getData').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: function (h) { return h(App) }
  }).$mount('#app')
});