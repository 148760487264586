export default {
    namespaced: true,
    state: {
        showError: false,
        errorData: {}
    },
    mutations: {
        SHOW_ERROR(state, payload) {
            state.showError = true
            state.errorData = payload
        },
        CLOSE_ERROR(state) {
            state.showError = false
        }
    },
    actions: {
    },
    modules: {
    }
}