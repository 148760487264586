<template>
  <v-app
    :style="{
      'background-color':
        $router.currentRoute.name != 'Login' ? '#f2f4f8' : null,
    }"
  >
    <AppBar v-if="![null, 'Login'].includes($router.currentRoute.name)" />
    <Drawer v-if="![null, 'Login'].includes($router.currentRoute.name)" />

    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view />
      </v-slide-x-transition>
    </v-main>
    <AlgToast ref="notify" :objects.sync="objects" right />
    <ErrorDialog />
    <ParlamentarSelector ref="selector" />
    <ChangePassword ref="changePassword" />
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import Drawer from "@/components/Drawer";
import ErrorDialog from "@/components/ErrorDialog.vue";
import ParlamentarSelector from "@/components/ParlamentarSelector.vue";
import ChangePassword from "./components/ChangePassword.vue";

export default {
  name: "App",
  components: {
    AppBar,
    Drawer,
    ErrorDialog,
    ParlamentarSelector,
    ChangePassword,
  },
  mounted() {
    this.$root.notify = this.$refs.notify;
  },
  data: () => ({
    objects: [],
  }),
};
</script>

<style>
/* barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  background: #dad7d7;
}
</style>