<template>
  <v-app-bar
    app
    clipped-left
    elevation="1"
    color="primary"
    style="color: white"
  >
    <v-app-bar-nav-icon
      color="white"
      @click="$store.commit('drawerStore/changeDrawer')"
    ></v-app-bar-nav-icon>
    <v-badge
      color="warning"
      :value="env != 'production'"
      :content="env"
      overlap
    >
      <v-toolbar-title>
        <img width="150" src="@/assets/keniston_mini.png" />
      </v-toolbar-title>
    </v-badge>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-menu :close-on-content-click="false" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text color="white" v-bind="attrs" v-on="on">
            <div class="text-left hidden-sm-and-down mr-3">
              <span>{{ user.name }}</span>
              <br />
              <!-- <span v-if="!parlamentar._id" class="text-caption font-weight-light">
                Sem parlamentar selecionado
              </span> -->

              <span class="text-caption font-weight-light">
                {{ parlamentar.name }}
              </span>
            </div>
            <v-icon> mdi-account-circle </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon> mdi-account-circle </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="parlamentar._id">
              <v-list-item-avatar>
                <v-icon> mdi-account-tie</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ parlamentar.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ utils.getParlamentarRole(parlamentar.role) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="openPassword()"> Trocar Senha </v-btn>

            <v-btn text @click="openSelector()" v-if="showParlamentarBtn">
              Trocar Parlamentar
            </v-btn>
            <v-btn color="primary" text @click="LOGOUT()"> Logout </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import config from "@/config";
import { mapMutations, mapState } from "vuex";
import utils from "@/utils";

export default {
  name: "appbar",
  mounted() {
    this.env = process.env.NODE_ENV;
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      parlamentar: (state) => state.parlamentarDialogStore.selectedParlamentar,
    }),
    showParlamentarBtn() {
      if (this.user.master) return false;
      if (this.user.parlamentares){
        if (this.user.parlamentares.length == 1) return false;
      }
      return true;
    },
  },
  data: () => ({
    utils,
    config,
    env: "",
  }),
  methods: {
    ...mapMutations(["LOGOUT"]),

    openSelector() {
      this.$store.commit("parlamentarDialogStore/SHOW_PARLAMENTAR_DIALOG");
    },
    openPassword() {
      this.$store.commit("changePasswordStore/SHOW_PASSWORD_DIALOG");
    },
  },
};
</script>

<style scoped>
.v-toolbar__title {
  line-height: unset !important;
}
</style>