<template>
  <v-dialog
    v-if="parlamentarDialog"
    v-model="parlamentarDialog"
    max-width="450"
    :persistent="isPersistent"
    @click:outside="close(true)"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title class="text-h6 primary--background">
        <v-icon dense left color="white"> mdi-account-tie </v-icon>
        <span>Selecione um parlamentar</span>
      </v-card-title>
      <!-- <v-progress-linear indeterminate></v-progress-linear> -->
      <v-list>
        <v-list-item
          v-for="(item, i) in parlamentares"
          :key="i"
          :value="item"
          @click="setParlamentar(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>

            <v-list-item-subtitle>
              {{ item.partido }} -
              {{ utils.getParlamentarRole(item.role) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions v-if="!isPersistent">
        <v-spacer></v-spacer>
        <v-btn color="warning" depressed @click="closeDialog()"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapState, mapMutations } from "vuex";
import utils from "@/utils";

export default {
  computed: {
    ...mapState({
      parlamentarDialog: (state) =>
        state.parlamentarDialogStore.parlamentarDialog,
    }),
  },
  watch: {
    parlamentarDialog(newValue) {
      if (newValue && this.parlamentares?.length == 0) {
        if (this.$store.state.user.master) {
          // get all parlamentares
        } else {
          this.parlamentares = this.$store.state.user.parlamentares;
        }
      }
    },
  },
  data: () => ({
    utils,
    parlamentares: [],
    resolve: null,
    isPersistent: false,
  }),
  methods: {
    ...mapMutations({
      closeDialog: "parlamentarDialogStore/CLOSE_PARLAMENTAR_DIALOG",
      openDialog: "parlamentarDialogStore/SHOW_PARLAMENTAR_DIALOG",
      selectParlamentar: "parlamentarDialogStore/SET_PARLAMENTAR",
    }),
    close(outside) {
      if (outside && this.isPersistent) return;
      this.isPersistent = false;
      this.parlamentares = [];
      this.closeDialog();
    },
    setParlamentar(item) {
      this.selectParlamentar(item);

      if (this.resolve) {
        // vem do login
        this.resolve();
      } else {
        this.$router.go();
      }
      this.isPersistent = false;
      this.closeDialog();
      this.resolve = null;
    },
    open(parlamentares) {
      if (parlamentares.length == 1) {
        this.selectParlamentar(parlamentares[0]);
        return;
      }
      this.parlamentares = parlamentares;

      // como esse open vem do login, é necessario que o modal seja persistente
      this.isPersistent = true;
      this.openDialog();
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
  },
};
</script>
  