<template>
  <v-dialog
    v-if="passwordDialog"
    v-model="passwordDialog"
    :persistent="isPersistent"
    @click:outside="close(true)"
    @keydown.esc="close()"
    max-width="450"
  >
    <v-card class="flexcard">
      <v-form
        ref="passwordForm"
        @submit.prevent="submitPassword"
        :readonly="isLoading"
        :disabled="isLoading"
      >
        <v-card-title class="text-h6 primary--background">
          <v-icon dense left color="white"> mdi-key </v-icon>
          <span>Redefinir Senha</span>
        </v-card-title>
        <v-progress-linear indeterminate v-if="isLoading"></v-progress-linear>
        <v-card-text class="pa-3 overflow-y-auto grow">
          <div v-if="resolve" class="font-weight-medium">
            Para sua segurança, redefina sua senha
          </div>
          <v-text-field
            :readonly="isLoading"
            prepend-inner-icon="mdi-email"
            label="Senha Atual"
            v-model="form.oldPassword"
            :rules="[rules.required, isSame]"
            hide-details="auto"
            validate-on-blur
          ></v-text-field>
          <v-text-field
            :readonly="isLoading"
            prepend-inner-icon="mdi-email"
            label="Nova Senha"
            v-model="form.newPassword"
            :rules="[rules.required, isSame]"
            hide-details="auto"
            validate-on-blur
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isPersistent"
            color="warning"
            depressed
            @click="close()"
            :disabled="isLoading"
          >
            Fechar
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            depressed
            @click="submitPassword()"
            :disabled="isLoading"
            :loading="isLoading"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { mapState, mapMutations } from "vuex";
import http from "@/plugins/axios";

export default {
  computed: {
    ...mapState({
      passwordDialog: (state) => state.changePasswordStore.passwordDialog,
    }),
  },
  data: () => ({
    form: {},
    isLoading: false,
    resolve: null,
    isPersistent: false,
    id: undefined,
    token: undefined,
    rules: {
      required: (value) => !!value || "Campo obrigatório",
    },
  }),
  methods: {
    ...mapMutations({
      closeDialog: "changePasswordStore/CLOSE_PASSWORD_DIALOG",
      openDialog: "changePasswordStore/SHOW_PASSWORD_DIALOG",
    }),
    open(id, token) {
      // como esse open vem do login, é necessario que o modal seja persistente
      this.token = token;
      this.id = id;
      this.isPersistent = true;
      this.openDialog();
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close(outside) {
      if (this.isLoading) return;
      if (outside && this.isPersistent) return;

      this.id = undefined;
      this.token = undefined;
      this.isPersistent = false;
      this.form = {};
      this.closeDialog();
      this.resolve = null;
    },
    async submitPassword() {
      if (this.$refs.passwordForm.validate()) {
        this.isLoading = true;
        this.isPersistent = true;

        try {
          let id = this.$store.state.user._id ?? this.id;

          let config = undefined;
          if (this.token) {
            config = {
              headers: { Authorization: `Bearer ${this.token}` },
            };
          }

          await http.patch("/users/" + id, this.form, config);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
          if (!this.resolve) this.isPersistent = false;

          this.$root.notify.showErrorToast(`Falha ao trocar a senha`);
          return;
        }
        this.isLoading = false;
        this.$root.notify.showSuccessToast(`Senha alterada`);

        if (this.resolve) {
          // vem do login
          this.resolve();
        }
        this.close();
      }
    },
    isSame() {
      return (
        this.form.oldPassword != this.form.newPassword ||
        "Os valores devem ser diferentes"
      );
    },
  },
};
</script>
  

<style>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>