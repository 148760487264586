<template>
  <v-dialog
    v-if="errorDialog"
    v-model="errorDialog"
    max-width="450"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
  >
    <v-card>
      <v-card-title class="text-h6 primary--background">
        <span>Ocorreu um erro :(</span>
      </v-card-title>
      <v-card-text class="pt-3 text-justify">
        Tivemos um erro interno e sua solicitação não pode ser concluída.
        Informe o seguinte código ao suporte:

        <p class="ma-0 text-h6">{{ errorData.data.data.supportTicket }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" depressed @click="closeDialog()"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      errorDialog: (state) => state.errorStore.showError,
      errorData: (state) => state.errorStore.errorData,
    }),
  },
  data: () => ({
    teste: true,
  }),
  methods: {
    ...mapMutations({
      closeDialog: "errorStore/CLOSE_ERROR",
    }),
  },
};
</script>
  