import Vue from 'vue'
import VueRouter from 'vue-router'

import Cookies from "js-cookie";

Vue.use(VueRouter)

const env = process.env.NODE_ENV;

console.log(env);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login.vue'),
    beforeEnter: (to, from, next) => {
      const isLogged = Cookies.get('token') != undefined
      if (isLogged) next('/') //Não vai pro login se estiver logado
      next();
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: () => import('@/views/dashboard/dashboard.vue')
  },
]

const router = new VueRouter({
  base: `/${env}`,
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const isLogged = Cookies.get('token') != undefined
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // se a pagina não for encontrada
  if (!to.matched.length) next('/')

  if (requiresAuth && !isLogged) next('login')
  else next()
});

export default router;

export function resetRouter() {
  const newRouter = new VueRouter({
    base: `/${env}`,
    mode: 'history',
    routes
  })
  router.matcher = newRouter.matcher // the relevant part
}
