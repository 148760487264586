export default {
    namespaced: true,
    state: {
        showDrawer: window.innerWidth > 1264,
    },
    mutations: {
        changeDrawer(state) {
            state.showDrawer = !state.showDrawer
        }
    },
    actions: {
    },
    modules: {
    }
}