<template>
  <v-navigation-drawer
    v-model="$store.state.drawerStore.showDrawer"
    v-if="$router.currentRoute.name != 'Login'"
    app
    clipped
    width="200"
  >
    <v-list nav dense>
      <div v-for="(link, i) in drawerItens" :key="i">
        <v-list-item v-if="!link.subLinks" :to="link.to" color="primary">
          <v-icon left>{{ link.icon }}</v-icon>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          :value="activeGroup(link.subLinks)"
          v-else
          :key="link.text"
        >
          <template v-slot:activator>
            <v-icon left>{{ link.icon }}</v-icon>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </template>

          <div v-for="sublink in link.subLinks" :key="sublink.text">
            <v-list-item
              v-if="showService(sublink)"
              class="pl-5"
              :to="sublink.to"
            >
              <v-list-item-title>{{ sublink.text }}</v-list-item-title>
              <v-icon right dense>{{ sublink.icon }}</v-icon>
            </v-list-item>
          </div>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["drawerItens"]),
  methods: {
    activeGroup(group) {
      let temp = group.map((el) => el.to);
      return temp.includes(this.$router.currentRoute.path);
    },
    showService(service) {
      let serviceName = service.to.substring(1);
      return this.$store.state.permissions[serviceName].includes("MENU");
    },
  },
};
</script>

